import httpClient from '@/libs/http-client'

// 관리자 리스트
export function adminList (data) {
  const url = '/api/admin/setting/admin'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 관리자 추가
export function adminSave (data) {
  const url = '/api/admin/setting/adminSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// ip 리스트
export function adminIp (data) {
  const url = '/api/admin/setting/adminIp'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// ip 추가
export function adminIpSave (data) {
  const url = '/api/admin/setting/adminIpSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function adminTopRate (data) {
  const url = '/api/admin/topRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function adminPartnerSearch (data) {
  const url = '/api/admin/partnerSearch'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function adminHeadCreditInfo (data) {
  const url = '/api/admin/headCreditInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
